.conteneur_pied_page
{
    background: #362D2E;
    padding: 20px;
    color: white;
    margin:0px;
    margin-top:50px;
    font-size: 16px;
    width:100%;
    bottom: 0;
    text-align: center;
}
.texte_copyright
{
    padding:20px;
    margin: 0px;
}
.lien_bas_page
{
    margin: 10px;
    color: #E3B94C;
    font-weight: bold;
}
.lien_bas_page_gw
{
    color: #E3B94C;
    font-weight: bold;
}