.carte
{
    margin-top:10px;
    margin-bottom: 10px;
}
.texte_fonctionnalite
{
    color:black;
}
.titre_fonctionnalite
{
    color: black
}
.conteneur_bouton_app
{
    margin-top: 5%;
}
.conteneur_presentation_app
{
    margin: 50px;
}
.image_application
{
    height: 40em;
}