.bouton_retour_page_accueil
{
    background-color: #cd7f32;;
}
.bouton_retour_page_accueil:hover
{
    background-color: #cd7f32;;
}
.bouton_retour_page_accueil:focus
{
    background-color: #cd7f32;;
}