.conteneur_navigation
{
    display: flex;
    align-items: center;
    padding:25px;
    padding-right:50px;
    background-color: #362D2E;
    color: white;
    justify-content: center;
}
.conteneur_navigation_non_connecte
{
    background: #362D2E;
    padding: 25px;
}
.hf_titre_logo_icone
{
    font-size: 100px;
    color: #cd7f32;
    font-family: LTBeverage;
    padding: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 10px;
    background-color: white;
}
.hf_sous_titre_logo
{
    font-size: 24px;
    font-style: italic;
}