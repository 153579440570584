@font-face {
  font-family: LTBeverage;
  src: local('LTBeverage'), url("./polices/LTBeverage.ttf") format('truetype');
}
@font-face {
  font-family: Gabrielle;
  src: local('Gabrielle'), url("./polices/Gabrielle.ttf") format('truetype');
}
body
{
  background-color: rgba(24, 17, 14, 0.8) !important;
  background-size: contain;
  color:white;
}
.row
{
  margin: 0px;
}
.navbar-brand
{
  display: flex;
  align-items: center;
}
.nav-link
{
  color:white !important;
}
.nav-link:hover
{
  font-weight: bold;
}
.dropdown-item:hover
{
  background-color:rgba(255, 166, 0, 1);
}
.dropdown-item:focus
{
  background-color:rgba(255, 166, 0, 1);
}
svg
{
  fill:white;
}
.btn
{
  background-color: rgba(255, 166, 0, 1);
  border: 0px;
}
.btn:hover
{
  background-color: rgba(255, 166, 0, 0.8);
  border: 0px;
}
.btn:focus
{
  background-color: rgba(255, 166, 0, 0.8);
  border: 0px;
}
.alert-warning, .alert-danger, .alert-success
{
  background-color: #2c3034;
  border: 0px;
  color: white;
}
a
{
  color: white;
  text-decoration: none;
}
a:hover
{
  color: white;
}
.badge
{
  background-color: rgba(255, 166, 0, 0.8) !important;
  cursor: pointer;
}